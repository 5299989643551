import React from 'react'
import { graphql, Link } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import {GatsbyImage} from 'gatsby-plugin-image'
import { Container } from '../Container'
import { Carousel } from '../Carousel'
import * as s from './Listing.module.scss'
import { FaChevronRight, FaHouseChimney, FaHouseUser } from 'react-icons/fa'
import { BsFillHousesFill } from 'react-icons/bs'
import slugify from '../../utils/slugify'

const colours = {
  'First Nation': '#DB6642',
  'Métis Community': '#468A7F',
  'Inuit Community': '#FFE45C'
}

export const ListingCommunities = ({data, labels, lang}) => {
  
  return (
    <section style={{background: '#F8E4D2'}} className={s.CarouselContainer}>
      <Container>
        <div>
          <PrismicRichText field={labels.communities_header?.richText}/>
        
          <Carousel>
            {data.communities?.sort((a,b) => {
                if(a.data?.Name < b.data?.Name) return -1;
                if(a.data?.Name > b.data?.Name) return 1;
                return 0;
              })
              .map((com, i) => {
              const url = `/${lang.slice(0,2)}/communities/${slugify(com.data.Name)}`
              const description = com.data?.Description?.length > 120 ? com.data?.Description.substr(0,120) + "..." : com.data?.Description
              return (
                <div className={s.CommunityTile} key={i}>
                  <span className={s.Tag} style={{background: colours[com.data?.Type]}}>{com.data?.Type}</span>
                  <div className={s.TileLogo}>
                    {
                      com.data.Logo ?
                      !com.data.Logo.localFiles[0].childImageSharp  ?
                       
                        <>
                          <FaHouseUser/>
                        </>
                      :
                        <GatsbyImage 
                          image={com.data?.Logo?.localFiles[0].childImageSharp?.gatsbyImageData} 
                          imgStyle={{objectFit: 'contain', objectPosition: 'left center'}}
                          alt=""
                        />
                      : (
                        <>
                          <FaHouseUser/>
                        </>
                      )                      
                    }
                    
                  </div>
                  <span className={s.CommunityName}>{com.data?.Name}</span>
                  <p>{description}</p>
                  <Link to={url} className={s.MoreBtn}>{labels.community_btn_label}<FaChevronRight/></Link>
                </div>
            )})}
          </Carousel>
        </div>
      </Container>
    </section>
  )
}
export const query = graphql`
  fragment AirtableFragCommunities on Airtable {
    data {
      Communities {
        data {
          Name
          Description
          Community_URL
          Type
          Logo {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
              extension
              src: publicURL
            }
            raw {
              url
            }
          }
        }
      }
    }
  }
  fragment CommonFragCommunities on PrismicListingCommon {
    data {
      communities_header {
        richText
      }
      community_btn_label
    }
  }
`